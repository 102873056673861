import  React, { useState, useEffect }  from "react";
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss';

function PlayVideo(props) {
    const [showvideo, setShowvideo] = useState(false);
    useEffect(() => {
        setShowvideo(true);
    });
    return (
        <React.Fragment>
            {showvideo &&
                <ModalVideo 
                channel='youtube' 
                autoplay 
                isOpen={props.isOpen} 
                videoId={props.videoId}
                autoplay={1}
                onClose={ () => props.isCloseFunction(false)}
                />
            }
        </React.Fragment>
    )
}

export default PlayVideo;